<template>
  <v-list>
    <v-list-item
      color="white"
      v-for="item in menu"
      link
      :key="item.id"
      :to="item.link"
    >
      <v-list-item-icon>
        <unicon :name="item.icon" fill="#ffffff"></unicon>
      </v-list-item-icon>
      <v-list-item-title class="white--text">{{ item.name }}</v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "Aside",
  data() {
    return {
      role: 0,
      menu: [],
      menuAdmin: [
        {
          id: 1,
          icon: "estate",
          name: this.$t("MENU.home"),
          link: "/dashboard"
        },
        {
          id: 2,
          icon: "users-alt",
          name: this.$t("MENU.users"),
          link: "/admin/users"
        },
        { id: 3, icon: "database", name: "Manage Data", link: "/admin/manage" },
        {
          id: 4,
          icon: "bell",
          name: "Send Notification",
          link: "/admin/notify"
        },
        {
          id: 5,
          icon: "pound-circle",
          name: this.$t("MENU.payments"),
          link: "/admin/payments"
        },
        { id: 6, icon: "comments-alt", name: "Messages", link: "/chat" },
        {
          id: 7,
          icon: "setting",
          name: this.$t("MENU.general_settings"),
          link: "/admin/app-settings"
        }
      ],
      menuDoctor: [
        {
          id: 1,
          icon: "estate",
          name: this.$t("MENU.home"),
          link: "/dashboard"
        },
        {
          id: 2,
          icon: "calender",
          name: this.$t("MENU.appointment"),
          link: "/appointments"
        },
        {
          id: 3,
          icon: "comment-redo",
          name: this.$t("MENU.second_opinion"),
          link: "/doctor/referral"
        },
        {
          id: 4,
          icon: "clock",
          name: this.$t("MENU.schedule"),
          link: "/doctor/schedule"
        },
        {
          id: 5,
          icon: "comment-alt-question",
          name: this.$t("MENU.questions"),
          link: "/advice"
        },
        { id: 6, icon: "comments-alt", name: "Messages", link: "/chat" },
        { id: 7, icon: "list-ul", name: "Posts", link: "/doctor/posts" }
      ],
      menuPatient: [
        {
          id: this.$root.lang + 1,
          icon: "estate",
          name: this.$t("MENU.home"),
          link: "/dashboard"
        },
        {
          id: this.$root.lang + 2,
          icon: "file-medical-alt",
          name: this.$t("MENU.medical_file"),
          link: `/medical_file/${this.$root.mf}`
        },
        {
          id: this.$root.lang + 3,
          icon: "calender",
          name: this.$t("MENU.appointment"),
          link: "/appointments"
        },
        {
          id: this.$root.lang + 4,
          icon: "comment-alt-question",
          name: this.$t("MENU.questions"),
          link: "/advice"
        }
      ]
    };
  },
  components: {},
  watch: {
    role(r) {
      switch (r) {
        case "1":
          this.menu = this.menuAdmin;
          break;
        case "3":
          this.menu = this.menuDoctor;
          break;
        default:
          this.menu = this.menuPatient;
      }
    }
  },
  mounted() {
    this.role = JSON.parse(localStorage.userInfo).role;
    this.$util.EventBus.$on("langChanged", () => {
      this.$nextTick(() => {
        // console.log("lang change event in aside",data)
        this.menuAdmin = [
          {
            id: 1,
            icon: "estate",
            name: this.$t("MENU.home"),
            link: "/dashboard"
          },
          {
            id: 2,
            icon: "users-alt",
            name: this.$t("MENU.users"),
            link: "/admin/users"
          },
          {
            id: 3,
            icon: "database",
            name: "Manage Data",
            link: "/admin/manage"
          },
          {
            id: 4,
            icon: "bell",
            name: "Send Notification",
            link: "/admin/notify"
          },
          {
            id: 5,
            icon: "pound-circle",
            name: this.$t("MENU.payments"),
            link: "/admin/payments"
          },
          { id: 6, icon: "comments-alt", name: "Messages", link: "/chat" },
          {
            id: 7,
            icon: "setting",
            name: this.$t("MENU.general_settings"),
            link: "/admin/app-settings"
          }
        ];
        this.menuDoctor = [
          {
            id: 1,
            icon: "estate",
            name: this.$t("MENU.home"),
            link: "/dashboard"
          },
          {
            id: 2,
            icon: "calender",
            name: this.$t("MENU.appointment"),
            link: "/doctor/appointment"
          },
          {
            id: 3,
            icon: "comment-redo",
            name: this.$t("MENU.second_opinion"),
            link: "/doctor/referral"
          },
          {
            id: 4,
            icon: "clock",
            name: this.$t("MENU.schedule"),
            link: "/doctor/schedule"
          },
          {
            id: 5,
            icon: "comment-alt-question",
            name: this.$t("MENU.questions"),
            link: "/advice"
          },
          { id: 6, icon: "comments-alt", name: "Messages", link: "/chat" },
          { id: 7, icon: "list-ul", name: "Posts", link: "/doctor/posts" }
        ];
        this.menuPatient = [
          {
            id: this.$root.lang + 1,
            icon: "estate",
            name: this.$t("MENU.home"),
            link: "/dashboard"
          },
          {
            id: this.$root.lang + 2,
            icon: "file-medical-alt",
            name: this.$t("MENU.medical_file"),
            link: `/medical_file/${this.$root.mf}`
          },
          {
            id: this.$root.lang + 3,
            icon: "calender",
            name: this.$t("MENU.appointment"),
            link: "/appointments"
          },
          {
            id: this.$root.lang + 4,
            icon: "comment-alt-question",
            name: this.$t("MENU.questions"),
            link: "/advice"
          }
        ];
        switch (this.role) {
          case "1":
            this.menu = this.menuAdmin;
            break;
          case "3":
            this.menu = this.menuDoctor;
            break;
          default:
            this.menu = this.menuPatient;
        }
        // console.log("menu", this.menuPatient)
      });
    });

    // Init Aside
    //// console.log("going to load medical file id now");
    //this.loadMedicalID();
  },
  methods: {}
};
</script>
